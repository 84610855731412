<template>
  <el-dialog width="1200px" custom-class="zg-dialog" title="选择案例" center
    @open="getList()"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :visible.sync="dialogvisible">
    <div class="custom-scroll-bar content">
      <el-table class="item" ref="multipleTable"
        :data="caseList" 
        @selection-change="selectionChange">
        <!-- 选择框 -->
        <el-table-column type="selection" width="50"></el-table-column>

        <!-- 案例名称 -->
        <el-table-column prop="title" label="案例名称"></el-table-column>
        
        <!-- 更新时间 -->
        <el-table-column label="更新时间">
          <template slot-scope="scope">{{ scope.row.updatedAt | dateFormat }}</template>
        </el-table-column>

        <!-- 创建者 -->
        <el-table-column prop="userName" label="创建者"></el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="page-footer">
        <el-pagination layout="total, prev, pager, next, jumper"
          @current-change="getList"
          :background="true"
          :current-page="page.index"
          :page-size="page.number"
          :total="page.total">
        </el-pagination>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogvisible = false">取 消</el-button>
      <el-button type="primary" @click="updateData()">确 定</el-button>
    </span> 
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      dialogvisible: this.visible,
      caseList: [], // 团队列表
      page: {
        index: 1,
        number: 10,
        total: 1
      },
      selectedCase: [], // 选中的商品
      defaultSelected: []
    }
  },
  methods: {
    // 设置属性
    setOptions(opts) {
      if(opts.list) this.defaultSelected = opts.list;
    },

    // 选中商品
    selectionChange(val){
      this.selectedCase = val;
    },
    
    // 获取商品列表
    async getList(pageIndex){
      this.page.index = pageIndex || 1;
      let params = {
        type: 9,
        status: 1,
        pageIndex: this.page.index,
        pageNumber: this.page.number
      }
      let res = await this.$ZGManager.getMaterialList(params);
      if(res.status != 200) return;
      this.page.total = res.data.totalRows;
      this.caseList = res.data.list;

      // 初始化选中状态
      this.$nextTick(() => {
        let data = [];
        this.caseList.map((e, index) => {
          this.defaultSelected.map((val) => {
            if(e.id == val.id) data.push(index);
          })
        })
        data.map(e => this.$refs.multipleTable.toggleRowSelection(this.caseList[e], true));
      });
    },

    // 更新数据
    updateData(){
      if(this.selectedCase.length > 3) return this.$message.error("最多只能选择3个商品");
      let data = [];
      this.selectedCase.map((e) => {
        data.push({
          id: e.id,
          title: e.title,
          userName: e.userName,
          logo: JSON.parse(e.configs).logo
        })
      });
      this.$emit('selectCase', data);
      this.dialogvisible = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.content{
  height: 540px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -20px;
  margin-bottom: -20px;
  .item{
    height: 484px;
  }
  .page-footer{
    float: right; 
    margin-top: 20px; 
    margin-right: 20px;
  }
}
</style>