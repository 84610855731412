<template>
  <el-dialog 
    width="800px"
    custom-class="zg-dialog"
    title="上传图片"
    @open="uploadImage = ''"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :visible.sync="dialogvisible">
    <div class="content">
      <p class="tips-head">
        <template v-if="(cropWidth != 200) && (cropHeight != 150)">
          图片建议尺寸：{{cropWidth}}*{{cropHeight}}，
        </template>
        类型必须是jpeg,jpg,png中的一种。
        <template v-if="imageSize > 0">
          图片大小不能超过{{imageSize}}M
        </template>
      </p>
      <div class="left">
        <p class="tips">裁剪操作框</p>
        <vueCropper
          ref="cropper"
          outputType="jpeg || png"
          autoCrop
          @realTime="prewImage"
          :enlarge="imageEnlarge"
          :img="uploadImage"
          :autoCropWidth="cropWidth"
          :autoCropHeight="cropHeight"
          :fixedNumber="fixedArray"
          :fixed="isFixed"
          :fixedBox="isfixedBox">
        </vueCropper>
      </div>
      <div class="right">
        <p class="tips">裁剪预览框</p>
        <div v-show="!previews.url" class="image-tips">请选择图片后预览哦</div>
        <div :style="previewContent"> 
          <div :style="previews.div">
            <img :src="previews.url" :style="previews.img">
          </div>
        </div>
      </div>
      <div class="button" slot="footer">        
        <el-upload 
          class="upload-demo"
          ref="upload"
          action="api/upload/file"
          accept="image/jpg,image/jpeg,image/png"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="uploadPreview">
          <el-button type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button>
        </el-upload>
        <el-button @click="scaleRotateImage('enlarge')" plain class="el-icon-plus"></el-button>
        <el-button @click="scaleRotateImage('narrow')" plain class="el-icon-minus"></el-button>
        <el-button @click="scaleRotateImage('left')" plain class="el-icon-refresh-left"></el-button>
        <el-button @click="scaleRotateImage('right')" plain class="el-icon-refresh-right"></el-button>
        <el-button @click="uploadScreenshot()" type="primary" style="float: right">确定</el-button>
      </div>
    </div>    
  </el-dialog>
</template>

<script>
import { VueCropper } from 'vue-cropper'
import { promisify } from '@/js/Utils'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      dialogvisible: this.visible,
      sacle: 1,
      uploadImage: '',
      previewContent: {}, // 预览容器样式
      previews: {}, // 预览图片信息
      cropWidth: 'max',
      cropHeight: 'max',
      isFixed: true,
      fixedArray: ['2', '1.5'],
      imageSize: 0,
      imageEnlarge: 2,
      isfixedBox: false
    }
  },
  mounted() {
    
  },
  methods: {
    // 缩放/旋转图片
    scaleRotateImage(type){
      switch(type){
        case 'left':
          this.$refs.cropper.rotateLeft();
          break;
        case 'right':
          this.$refs.cropper.rotateRight();
          break;
        case 'enlarge':
          this.$refs.cropper.changeScale(1);
          break;
        case 'narrow':
          this.$refs.cropper.changeScale(-1);
          break;
      }
    },

    // 预览截图
    prewImage(data){
      let previews = data;
      this.previewContent = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        background: "#ccc"
      };
      this.previews = data;
    },

    // 预览本地图片
    uploadPreview(file, list){
      if(this.imageSize > 0 && (file.size > this.imageSize * 1024 * 1024)){
        return this.$message.error(`图片大小超过了${this.imageSize}M`);
      }
      let reader = new FileReader();
      reader.onload = (event) => {this.uploadImage = reader.result};
      reader.readAsDataURL(file.raw);
    },

    // 将截图上传至服务器
    async uploadScreenshot(){
      let formData = new FormData();
      let getBlob = promisify(this.$refs.cropper.getCropBlob);
      let data = await getBlob();
      formData.append("file", data, 'imahe.png');
      let res = await this.$ZGManager.uploadFile(formData);
      if(res.data.status == 0){
        this.$emit("uploadImageAndCrooper", res.data.data);
        this.dialogvisible = false;
      }
    },

    // 设置属性
    setOptions(opts) {
      opts.hasOwnProperty('cropWidth') ? this.cropWidth = opts.cropWidth : this.cropWidth = 200;
      opts.hasOwnProperty('cropHeight') ? this.cropHeight = opts.cropHeight : this.cropHeight = 150;
      if(opts.hasOwnProperty('fixedArray')) this.fixedArray = opts.fixedArray;
      if(opts.hasOwnProperty('imageSize')) this.imageSize = opts.imageSize;
      if(opts.hasOwnProperty('isFixed')) this.isFixed = opts.isFixed;
      if(opts.hasOwnProperty('imageEnlarge')) this.imageEnlarge = opts.imageEnlarge;
      if(opts.hasOwnProperty('isfixedBox')) this.isfixedBox = opts.isfixedBox;
    },
  },
  components: {
    VueCropper
  }
}
</script>

<style lang="scss" scoped>
.content{
  margin-top: -30px;
  overflow: hidden;
}
.left{
  width: 360px;
  height: 360px;
  float: left;
}
.right{
  width: 360px;
  height: 360px;
  float: right;
  .image-tips{
    display: inline-block;
    color: #888;
    text-align: center;
    width: 100%;
    height: 100%;
    line-height: 360px;
    background-color: #f7f7f7;
  }
}
.button{
  width: 100%;
  float: left;
  margin-top: 30px;
}
.upload-demo{
  display: inline-block;
}
.tips-head{
  font-size: 14px;
  color: rgb(255, 69, 0);
  line-height: 30px;
}
.tips{
  font-size: 14px;
  color: rgba(0, 0, 0, .65);
  margin-bottom: 5px;
}
</style>