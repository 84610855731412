<template>
  <el-dialog width="600px" title="选择员工"
    @open="getStaffList"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :visible.sync="dialogvisible">
    <div class="tree-content" >
      <el-tree class="tree" ref="tree" node-key="id" show-checkbox
        :indent="10"
        :default-expanded-keys="defaultExpandedKeys"
        :props="props"
        :data="staffList">
      </el-tree>
    </div>
    <div slot="footer" class="page-footer">
      <el-button @click="dialogvisible = false; show = false">取 消</el-button>
      <el-button type="primary" @click="getAllSelectedNode">确 定</el-button>
    </div> 
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default(){ 
        return []
      }
    }
  },
  data(){
    return {
      dialogvisible: this.visible,  // 弹窗开关 
      company: null,                // 公司信息
      props: {
        isLeaf: "leaf"
      },
      show: true,
      staffList: [],                // 员工列表
      selectedStaffList: [],        // 已经选中的员工列表
      defaultExpandedKeys: []
    }
  },
  mounted() {
    this.getStaffList();
  },
  methods: {
    // 设置属性
    setOptions(opts) {
      if(opts.staffList) {
        this.selectedStaffList = opts.staffList;
      }
      if(opts.defaultKey){
        this.defaultExpandedKeys = opts.defaultKey;
      }else{
        this.defaultExpandedKeys = ['company', 'team'];
      }
    },

    // 加载人员列表
    async getStaffList(){
      // 加载公司信息
      let company = await this.$ZGManager.getMyCompany();
      if(company.status != 200) return;
      
      // 获取公司所有成员列表
      let res = await this.$ZGManager.getTeamMember(0);
      if(res.status != 200) return;

      // 创建一个员工分组数组，循环后台拿到的员工列表，如果没有teamId，则push到员工分组数组中，为一级类目。
      // 否则在员工分组数组中查找，如果该员工的teamID存在于员工分组数组中，则将该员工信息push到该分组的children中。
      // 否则用该员工的teamId和teamName创建一个分组，push到员工分组数组中，并将该员工信息push到该分组的children中
      let staff = [];
      res.data.map((e) => {
        if(!e.teamId){
          staff.push({id: e.id, label: e.name, type: "staff", data: e, children: []});
        }else{
          let index = staff.findIndex(item => e.teamId == item.id);
          if(index != -1){
            staff[index].children.push({id: e.id, label: e.name, type: "staff", data: e, children: []})
          }else{
            staff.push({id: e.teamId, label: e.teamName, type: "team", children: []});
            staff[staff.length - 1].children.push({id: e.id, label: e.name, type: "staff", data: e, children: []});
          }
        }
      });
      this.staffList = [{id: "company", label: company.data.name, type: "company", children: staff}];

      // 如果之前选中了员工，则将tree中的员工也选中
      let keys = [];
      this.selectedStaffList.map((e) => {
        keys.push(e.id);
        this.defaultExpandedKeys.push(e.id);
      });
      this.$refs.tree.setCheckedKeys(keys);
    },

    // 获取已选中的人员
    getAllSelectedNode(){
      let data = this.$refs.tree.getCheckedNodes();
      this.$emit('selectStaff', data.filter(e => e.type == "staff"));
      this.dialogvisible = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.tree-content{
  max-height: 400px;
}
.page-footer{
  margin-top: 20px; 
  text-align: center;
}
</style>