import Cropper from "@/websites/cms/components/dialog/UploadImage"
import Team from '@/websites/cms/components/dialog/SelectTeam'
import Staff from '@/websites/cms/components/dialog/SelectStaff'
import Article from '@/websites/cms/components/dialog/SelectArticle'
import Brochure from '@/websites/cms/components/dialog/SelectBrochure'
import Goods from '@/websites/cms/components/dialog/SelectGoods'
import Case from '@/websites/cms/components/dialog/SelectCase'
import Matter from '@/websites/cms/components/dialog/SelectMatter'
import PreviewResource from '@/websites/cms/components/dialog/PreviewResource'
const nop = () => {};
let dialogs = {};
let fn_select;
export default {
  show(opts) {
    let type = opts.type || 'crooperImage';
    let dialog = dialogs[type];

    if(!dialog){
      // 创建一个临时div，挂载到body
      let el = document.createElement("div");
      document.body.appendChild(el);
      
      // 实例化一个login组件
      switch(type){ 
        case 'crooperImage':
          const crooperConstructor = Vue.extend(Cropper);
          dialog = new crooperConstructor({ el: el });
          dialog.$on("uploadImageAndCrooper", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'selectTeam':
          const teamConstructor = Vue.extend(Team);
          dialog = new teamConstructor({ el: el });
          dialog.$on("selectTeam", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'selectStaff':
          const staffConstructor = Vue.extend(Staff);
          dialog = new staffConstructor({ el: el });
          dialog.$on("selectStaff", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'selectArticle':
          const articleConstructor = Vue.extend(Article);
          dialog = new articleConstructor({ el: el });
          dialog.$on("selectArticle", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'selectBrochure':
          const brochureConstructor = Vue.extend(Brochure);
          dialog = new brochureConstructor({ el: el });
          dialog.$on("selectBrochure", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'selectGoods':
          const goodsConstructor = Vue.extend(Goods);
          dialog = new goodsConstructor({ el: el });
          dialog.$on("selectGoods", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'selectCase':
          const caseConstructor = Vue.extend(Case);
          dialog = new caseConstructor({ el: el });
          dialog.$on("selectCase", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'selectMatter':
          const matterConstructor = Vue.extend(Matter);
          dialog = new matterConstructor({ el: el });
          dialog.$on("selectMatter", item => {
            fn_select.apply(this, [item]);
          });
          break;
        case 'previewResource':
          const PreviewResourceConstructor = Vue.extend(PreviewResource);
          dialog = new PreviewResourceConstructor({ el: el });
          dialog.$on("previewResource", item => {
            fn_select.apply(this, [item]);
          });
          break;
        default: break;
      }
      dialogs[type] = dialog;
    }
    fn_select = opts.selected || nop;
    dialog.setOptions && dialog.setOptions(opts);
    dialog.$data.dialogvisible = true;
  }
}