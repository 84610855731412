<template>
  <el-dialog width="1200px" custom-class="zg-dialog" title="选择文章" center
    @open="getList()"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :visible.sync="dialogvisible">
    <div class="custom-scroll-bar content">
      <el-table ref="multipleTable" row-key="id"
        :data="list" 
        @selection-change="selectionChange">
        <!-- 选择框 -->
        <el-table-column type="selection" width="50"></el-table-column>

        <!-- 文章图片 -->
        <el-table-column label="封面" width="70px">
          <template slot-scope="scope">
            <div class="comp-selectarticle__logo">
              <img :src="scope.row.logo || $configs.headImg">
            </div>
          </template>
        </el-table-column>

        <!-- 案例名称 -->
        <el-table-column prop="title" label="文章名称"></el-table-column>
        
        <!-- 更新时间 -->
        <el-table-column label="更新时间">
          <template slot-scope="scope">{{ scope.row.updatedAt | dateFormat }}</template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="page-footer">
        <el-pagination layout="total, prev, pager, next, jumper"
          @current-change="getList"
          :background="true"
          :current-page="page.index"
          :page-size="page.number"
          :total="page.total">
        </el-pagination>
        <div class="page-footer__button">
          <el-button @click="dialogvisible = false">取 消</el-button>
          <el-button type="primary" @click="updateData()">确 定</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      dialogvisible: this.visible,
      list: [], // 团队列表
      page: {
        index: 1,
        number: 5,
        total: 1
      },
      selectedList: [], // 选中的商品
      defaultSelectedListList: []
    }
  },
  methods: {
    // 设置属性
    setOptions(opts) {
      if(opts.list) this.defaultSelectedList = opts.list;
      this.initListSelected();
    },

    // 选中数据
    selectionChange(val){
      this.selectedList = val;
    },
    
    // 获取列表
    async getList(pageIndex){
      this.page.index = pageIndex || 1;
      let params = {
        type: 0,
        status: 1,
        startTime: "2018-01-01 00:00:00",
        pageIndex: this.page.index,
        pageNumber: this.page.number
      }
      let res = await this.$ZGManager.getArticleList(params);
      if(res.status != 200) return;
      this.page.total = res.data.total_rows;
      this.list = res.data.list;
      this.initListSelected();
    },

    // 更新数据
    updateData(){
      this.$emit('selectArticle', this.selectedList);
      this.dialogvisible = false;
    },

    // 初始化选中状态
    initListSelected(){
      this.$nextTick(() => {
        let data = [];
        this.list.map((e, index) => {
          this.defaultSelectedList.map((val) => {
            if(e.id == val.id) data.push(index);
          })
        })
        data.map(e => this.$refs.multipleTable.toggleRowSelection(this.list[e], true));
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.content{
  overflow-x: hidden;
  overflow-y: auto;
  .page-footer{
    width: 100%;
    float: left; 
    margin-top: 20px; 
    margin-right: 20px;
    .page-footer__button{
      float: right;
      margin-top: -32px;
    }
  }
}
.comp-selectarticle__logo{
  width: 58px;
  height: 58px;
  background-color: #fafafa;
  position: relative;
  img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>