<template>
  <el-dialog custom-class="zg-dialog"
    :title="title"
    :center="true"
    :lock-scroll="true"
    :visible.sync="dialogvisible">

    <!-- 图片 -->
    <div v-if="type == 'image'" class="image-contain">
      <el-image :src="url" lazy fit="contain"></el-image>
    </div>

    <!-- 视频 -->
    <div v-else-if="type == 'video'" class="video-contain">
      <video class="video"
        :src="url"
        preload="auto"
        controls="controls"></video>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      dialogvisible: this.visible,
      resource: {},
      type: '',
      url: '',
      title: '',
      videoWidth: false
    }
  },
  methods: {
    // 设置属性
    setOptions(opts) {
      if(opts.resource) this.resource = opts.resource;
      if(opts.resourceType) this.type = opts.resourceType;
      if(opts.resourceTitle) this.title = opts.resourceTitle;
      if(opts.resourceUrl) this.url = opts.resourceUrl;
    },
  }
}
</script>

<style lang="scss" scoped>
.image-contain{
  ::v-deep img{
    max-width: 1200px;
    max-height: 80vh;
  }
}
.video-contain{
  .video{
    width: 100%;
    height: 70vh;
    background-color: #000;
  }
}
</style>