<template>
  <el-dialog width="1000px" title="选择内容"
    @open="initList"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :visible.sync="dialogvisible">
    <div class="dialog-content" >
      <!-- 选择内容弹窗 -->
      <div style="width: 100%">
        <el-radio-group v-model="currentType" @change="initList">
          <el-radio-button v-for="item in typeOption" 
            :key="item.id" :label="item.rt">{{item.name}}</el-radio-button>
        </el-radio-group>
      </div>
      
      <!-- 内容列表 -->
      <el-table class="content-table" highlight-current-row
        :data="list"
        @current-change="selectItem">
        <!-- 标题 -->
        <el-table-column v-if="currentType=='goods'" prop="name" label="标题"
          show-overflow-tooltip></el-table-column>
        <el-table-column v-else prop="title" label="标题"
          show-overflow-tooltip></el-table-column>

        <!-- 文章、视频来源 -->
        <el-table-column v-if="currentType=='article'||currentType=='video'" label="来源" width="50px">
          <template slot-scope="scope">{{scope.row.flag == 1 ? "员工" : "系统"}}</template>
        </el-table-column>

        <!-- 案例创建者 -->
        <el-table-column v-if="currentType=='case'||currentType=='brochure'" 
          prop="userName" label="创建者" width="200px"></el-table-column>

        <!-- 文件大小 -->
        <el-table-column v-if="currentType=='file'" label="大小" width="120px">
          <template slot-scope="scope">
            <span v-if="scope.row.configs">{{scope.row.configs.size}}</span>
          </template>
        </el-table-column>

        <!-- 商品 -->
        <el-table-column v-if="currentType=='goods'" label="商品单价/单位">
          <template slot-scope="scope">￥ {{scope.row.sales_price}}元 / {{scope.row.product_unit}}</template>
        </el-table-column>
        
        <!-- 更新时间 -->
        <el-table-column label="更新时间" width="180px">
          <template slot-scope="scope">{{scope.row.updated_at | dateFormat('YYYY-MM-DD HH:mm:ss')}}</template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="page-footer">
      <el-pagination class="page-paging" layout="prev, pager, next, jumper" background
        :current-page="page.index"
        :page-size="page.number"
        :total="page.totalPage"
        @current-change="initList"></el-pagination>
      <el-button type="primary" @click="selectItem('sure')">确 定</el-button>
    </div> 
  </el-dialog>
</template>

<script>
const defaultPage = {
  index: 1,
  number: 7,
  totalPage: 1
}
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      dialogvisible: this.visible,  // 弹窗开关 
      list: [],                     // 素材列表
      selected: {},                 // 已经选中的素材
      currentType: "article",       // 当前选中的类型
      typeOption: [                 // 类型
        {id: 1, rt: "article", name: "文章"},
        {id: 2, rt: "video", name: "视频"},
        {id: 8, rt: "goods", name: "商品"},
        {id: 3, rt: "brochure", name: "宣传册"},
        {id: 4, rt: "case", name: "案例"},
        // {id: 6, rt: "file", name: "文件"}
      ],
      currentType: "article",       // 当前选中的类型
      page: defaultPage,            // 分页数据
    }
  },
  methods: {
    // 初始化列表
    initList(param){
      let index = null;
      // param为数字时则为翻页行为，param为英文字符串时则为切换内容类型行为
      !isNaN(param) ? index = param: index = 1;
      if(index == 1) this.list = [];
      switch(this.currentType){
        case "article":
        case "video":
          this.getArticleVideoList(index); break; 
        case "goods":
          this.getGoodsList(index); break;
        case "brochure":
          this.getMatterList(index, 8); break;
        case "case":
          this.getMatterList(index, 9); break;
        case "file":
          this.getMatterList(index, "1,11,12,13,14,21"); break;
        default: break;
      }
    },

    // 选中的资源
    selectItem(item){
      if(item == "sure"){
        this.$emit('selectMatter', {type: this.currentType, item: this.selected});
        this.dialogvisible = false;
      }else{
        this.selected = item;
      }
    },

    // 查询文章、视频列表
    async getArticleVideoList(pageIndex){
      this.page.index = pageIndex;
      let params = {
        title: "",
        status: 1,
        type: this.currentType == "article" ? 0 : 1,
        startTime: "2018-01-01 00:00:00",
        pageIndex: this.page.index,
        pageNumber: this.page.number
      }
      let res = await this.$ZGManager.getArticleList(params);
      if(res.status != 200) return;
      this.list = res.data.list;
      this.page.totalPage = res.data.total_rows;
    },

    // 查询商品列表
    async getGoodsList(pageIndex){
      this.page.index = pageIndex;
      let params = {
        status: 1,
        type: "1,2",
        payStatus: "1,2"
      }
      let res = await this.$ZGManager.getGoodsList(params);
      if(res.status != 200) return;
      this.page.totalPage = res.data.totalRows;
      // 将缩略图的第一张转为logo
      res.data.list.map((e) => {
        e.updated_at = e.created_at;
        if(typeof e.thumbnail == "string"){
          try{
            let obj = JSON.parse(e.thumbnail);
            if(typeof obj == 'object' && obj ) e.logo = JSON.parse(e.thumbnail)[0];
          }catch{
            e.logo = "https://image.qll-times.com/2020/07/1bf486a77ee44e289f0488de30d3a79e.png";
          }
        }
      });
      this.list = res.data.list;
    },

    // 查询素材列表
    async getMatterList(pageIndex, type){
      this.page.index = pageIndex;
      let params = {
        type: type,
        title: this.searchText,
        status: this.searchStatus == 100 ? "" : this.searchStatus,
        pageIndex: this.page.index,
        pageNumber: this.page.number
      }
      let res = await this.$ZGManager.getMaterialList(params);
      if(res.status != 200) return;
      res.data.list.map((e) => {
        e.updated_at = e.updatedAt;
        e.configs = JSON.parse(e.configs);
      });
      this.list = res.data.list;
      this.page.totalPage = res.data.totalRows;
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-content{
  height: 360px;
  .content-table{
    width: 100%;
    margin-top: 10px;
  }
}
.page-footer{
  margin-top: 20px;
  .page-paging{
    float: left;
  }
}
</style>