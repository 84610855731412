<template>
  <el-dialog width="800px" custom-class="zg-dialog" title="选择部门" center
    @open="getCompany()"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :visible.sync="dialogvisible">
    <div class="custom-scroll-bar content">
      <el-tree class="tree" node-key="id" ref="selectTeam" show-checkbox
        @node-click="setTeam"
        :indent="10"
        :check-strictly="true"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :default-expanded-keys="[0]"
        :data="teamsList">
        <span slot-scope="{node, data}">
          <span class="zgcms">&#xe60d;{{node.label}}</span>
        </span>
      </el-tree>
    </div>
    <span slot="footer">
      <el-button @click="dialogvisible = false">取 消</el-button>
      <el-button type="primary" @click="updateData()">确 定</el-button>
    </span> 
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      dialogvisible: this.visible,
      teamsList: [], // 团队列表
    }
  },
  methods: {
    // 设置属性
    setOptions(opts) {},

    // 获取公司信息
    async getCompany(){
      this.teamsList = [];
      let res = await this.$ZGManager.getMyCompany();
      if(res.status == 200 && res.data){
        this.teamsList.push({
          id: 0,
          label: res.data.name,
          children: []
        });
        this.getMyTeams();
      }
    },

    // 获取我的团队列表
    async getMyTeams(){
      this.teamsList[0].children = [];
      let res = await this.$ZGManager.getMyTeams();
      if(res.status == 200){
        res.data.map((e) => {
          this.teamsList[0].children.push({
            id: e.id,
            label: e.name,
          })
        });
      }
    },

    // 点击选择部门(单选)
    setTeam(data){
      this.$refs['selectTeam'].setCheckedNodes([]);
      this.$refs['selectTeam'].setCheckedNodes([data]);
    },

    // 更新数据
    updateData(){
      let data = this.$refs['selectTeam'].getCheckedNodes();
      this.$emit('selectTeam', data[0]);
      this.dialogvisible = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.content{
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -20px;
  margin-bottom: -20px;
  .tree{
    height: 100%;
  }
  ::v-deep .el-tree-node{
    padding: 6px 0;
  }
}
</style>