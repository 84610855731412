<template>
  <el-dialog width="1200px" custom-class="zg-dialog" title="选择已上架的商品" center
    @open="getList()"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :visible.sync="dialogvisible">
    <div class="custom-scroll-bar content">
      <el-table class="item" ref="multipleTable"
        :data="goodsList" 
        @selection-change="selectionChange">
        <!-- 选择框 -->
        <el-table-column type="selection" width="50"></el-table-column>

        <!-- 商品图片 -->
        <el-table-column label="商品图片" width="80">
          <template slot-scope="scope">
            <div class="goods-logo" :style="{'background-image': `url(${ scope.row.logo })`}"></div>
          </template>
        </el-table-column>

        <!-- 商品名称 -->
        <el-table-column prop="name" label="商品名称"></el-table-column>
        
        <!-- 商品类别 -->
        <el-table-column prop="sort" label="商品类别">
          <template slot-scope="scope">{{ scope.row | goodsType }}</template>
        </el-table-column>

        <!-- 商品价格 -->
        <el-table-column label="商品单价">
          <template slot-scope="scope">{{ "￥" + scope.row.sales_price }}</template>
        </el-table-column>

        <!-- 创建时间 -->
        <el-table-column label="创建时间">
          <template slot-scope="scope">{{ scope.row.created_at | dateFormat }}</template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="page-footer">
        <el-pagination layout="total, prev, pager, next, jumper"
          @current-change="getList"
          :background="true"
          :current-page="page.index"
          :page-size="page.number"
          :total="page.total">
        </el-pagination>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogvisible = false">取 消</el-button>
      <el-button type="primary" @click="updateData()">确 定</el-button>
    </span> 
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      dialogvisible: this.visible,
      goodsList: [], // 团队列表
      page: {
        index: 1,
        number: 5,
        total: 1
      },
      selectedGoods: [], // 选中的商品
      defaultSelected: []
    }
  },
  methods: {
    // 设置属性
    setOptions(opts) {
      if(opts.list) this.defaultSelected = opts.list;
    },

    // 选中商品
    selectionChange(val){
      this.selectedGoods = val;
    },
    
    // 获取商品列表
    async getList(pageIndex){
      this.page.index = pageIndex || 1;
      let params = {
        status: 1,
        pageIndex: this.page.index,
        pageNumber: this.page.number
      }
      let res = await this.$ZGManager.getGoodsList(params);
      if(res.status != 200) return;
      res.data.list.map((e) => {
        if(typeof e.thumbnail == "string"){
          try{
            let obj = JSON.parse(e.thumbnail);
            if(typeof obj == 'object' && obj ) e.logo = JSON.parse(e.thumbnail)[0];
          }catch{
            e.logo = "https://image.qll-times.com/2020/07/1bf486a77ee44e289f0488de30d3a79e.png";
          }
        }
      });
      this.page.total = res.data.totalRows;
      this.goodsList = res.data.list;

      // 初始化选中状态
      this.$nextTick(() => {
        let data = [];
        this.goodsList.map((e, index) => {
          this.defaultSelected.map((val) => {
            if(e.id == val.id) data.push(index);
          })
        })
        data.map(e => this.$refs.multipleTable.toggleRowSelection(this.goodsList[e], true));
      });
    },

    // 更新数据
    updateData(){
      if(this.selectedGoods.length > 3) return this.$message.error("最多只能选择3个商品");
      this.$emit('selectGoods', this.selectedGoods);
      this.dialogvisible = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.content{
  height: 520px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -20px;
  margin-bottom: -20px;
  .item{
    height: 450px;
  }
  .goods-logo{
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .page-footer{
    float: right; 
    margin-top: 20px; 
    margin-right: 20px;
  }
}
</style>